import React from "react";

const SplitNoImage = () => {
  return (
    <>
      <div className="relative bg-white flex">
        <div
          style={{ placeSelf: "center" }}
          className="relative bg-red pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2"
        >
          <div className="">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h2
                style={{ color: "#1199FA" }}
                className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase"
              >
                PRIVACY POLICY
              </h2>
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Welcome to Defi Markets Pro
              </h3>
              <p className="mt-8 text-lg text-gray-500">
                Welcome to Defi Markets Pro, an international online Forex and
                CFDs trading firm offering 24 hours access to a diverse range of
                trading products including forex currency pairs, commodities,
                futures, indices and stocks. Through Global Market Trades and
                the world renowned MetaTrader 5 trading platform, you can take
                advantage of high liquidity, low spreads, mobile trading,
                technical analysis and even more.
              </p>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <p>
                  We maintain high standards to keep trading environments
                  secure, and offer the best tailor made trading conditions for
                  every client. We help Forex traders to develop the knowledge
                  and skills they need to trade efficiently and responsibly.
                </p>
                <br />

                <p>
                  <span className="font-bold"> 1. Introduction </span>
                  <br />
                  Your privacy is of utmost importance to us and it is our
                  policy to safeguard and respect the confidentiality of
                  information and the privacy of individuals. The following
                  Privacy Policy sets out how Defi Markets Pro collects, uses
                  and manages your personal information we receive by you or a
                  third party in connection with our provision of services to
                  you or which we collect from your use of our services and/or
                  our website (i.e., www.Defi Markets Pro.com) and/or any other
                  related websites and applications including, among others, the
                  Client Portal (www.my.Defi Markets Pro.com). This Privacy
                  Policy also informs you of your rights with respect to the
                  processing of your personal information. By using Defi Markets
                  Pro websites, you give your consent to the collection and use
                  of personal information by Global Market Trades as explained
                  in this Privacy Policy.
                </p>
                <br />
                <p>
                  <span className="font-bold"> 2. Data Collection</span>
                  <br />
                  As part of our daily business operations, we need to collect
                  personal information from our clients and prospective clients
                  in order to provide them with our products and services. When
                  you register for either a demo or a live account with Global
                  Market Trades, we collect your personal information directly
                  from you and indirectly as a result of your relationship with
                  us. By understanding your financial needs, we can treat you
                  fairly as a client; we can provide you with the most suitable
                  products and services, give you the appropriate information on
                  investment strategies, process your requests and transactions
                  and offer you both sales and post-sales services. We request
                  the following Personal Data from you: Personal and financial
                  information needed during the online registration
                  applications/forms; Documents needed for KYC and AML
                  procedures as proof of your identity and residency such as
                  Passport, National ID, utility bills, bank or credit card
                  statements; Set of corporate documents in case of corporate
                  clients. Please note that if you decide to end the business
                  relationship with Defi Markets Pro, we must keep your Personal
                  Data on record for the next five years.
                </p>
                <br />
                <p>
                  <span className="font-bold">
                    {" "}
                    3. Use of your Personal Data
                  </span>
                  <br />
                  Defi Markets Pro shall use your Personal Data for next
                  purposes: To verify your identity. To ensure that you meet the
                  suitability requirements needed to use our products and
                  services. To manage the account you have with us. To process
                  your transactions from and to payment service providers (PSPs)
                  and/or banking institutions. To process your trading orders.
                  To send you information about transaction/post-transaction
                  services. To keep you updated with news on our products,
                  services and any other information relevant to your business
                  relations with Defi Markets Pro. For website improvement
                  purposes. For the analysis of statistical data which will help
                  us provide you with better products and services in the
                  future.
                </p>
                <br />
                <p>
                  <span className="font-bold"> 4. Cookies</span>
                  <br />
                  Cookies are small text files sent from Web servers that may be
                  stored on your computer. They used to track website visitors.
                  We use cookies and web beacons (also known as action tags or
                  single-pixel gifs), and other technologies (collectively,
                  "cookies") to provide us with data we can use to improve your
                  experience with us and to know you better. Cookies enable us
                  to capture how you arrived at our site, when you return, which
                  pages on our site you visit, and to recognize that you are
                  already logged on when we receive a page request from your
                  browser. We will never share this information with any 3rd
                  party. Cookies are readable only by us and do not contain any
                  personal information nor do they contain account or password
                  information. Defi Markets Pro may set and access Global Market
                  Trades’s cookies on your computer, enabling us to learn which
                  advertisements and promotions bring our visitors or
                  prospective clients to our website. Defi Markets Pro or any of
                  its departments may use cookies in connection with Defi
                  Markets Pro’s products and services to track your activities
                  on our websites. Such information that we collect and share
                  would be anonymous and not personally identifiable. Defi
                  Markets Pro may share usage information about visitors to our
                  websites with reputable advertising companies for the purpose
                  of targeting our Internet banner or video advertisements. All
                  web browsers have settings that allow you to block cookies. By
                  visiting our website with your browser set to allow cookies,
                  you consent to our use of cookies as described above. If you
                  choose to block cookies you may use our services but some
                  functions may not work as designed
                </p>
                <br />
                <p>
                  <span className="font-bold">
                    {" "}
                    5. The security of your personal information
                  </span>
                  <br />
                  Defi Markets Pro is committed to safeguarding your Personal
                  Data by adhering to strict security standards and using the
                  most up to date security technologies. When you open an
                  account with us, you are issued a unique account number, User
                  ID and a password. Only a limited number of Global Market
                  Trades employees who have a need to know this information will
                  have access to your account number, User ID, and password.
                  However, please remember that you are ultimately responsible
                  for maintaining the secrecy of your account number, User ID
                  and password. We strongly recommend that you do not disclose
                  this information to anyone. We use proper SSL certificates to
                  authenticate our websites and secure trading and
                  customer-facing applications, allowing you to verify that you
                  are connected to our websites.
                </p>
                <br />
                <p>
                  <span className="font-bold">
                    {" "}
                    6. Disclosure of your Personal Information
                  </span>
                  <br />
                  Defi Markets Pro shall not disclose any of its clients’
                  confidential information to a third party, except: to extent
                  that it is required to do so pursuant to any applicable laws,
                  rules and/or regulations; if there is a duty to the public to
                  disclose; if our legitimate business interests require
                  disclosure; or at your request or with your consent or to
                  Persons described in this policy. Defi Markets Pro will
                  endeavor to make such disclosures on a ‘need-to-know’ basis,
                  unless otherwise instructed by a regulatory authority. Under
                  such circumstances, Defi Markets Pro will notify the third
                  party regarding the confidential nature of any such
                  information.
                </p>
                <br />
                <p>
                  <span className="font-bold"> 7. Your Privacy Rights</span>
                  <br />
                  You have certain rights in relation to your Personal You have
                  certain rights in relation to your Personal Information. You
                  can access your Personal Information and confirm that it
                  remains correct and up-to-date, request its deletion or choose
                  whether or not you wish to receive material from us or some of
                  our partners by logging into your Client Portal and submitting
                  a ticket accordingly. You are entitled to receive personal
                  information free of charge except in the following
                  circumstances where we may charge a reasonable fee to cover
                  our administrative costs of providing the personal information
                  for: manifestly unfounded or excessive/repeated requests, or
                  further copies of the same information. Alternatively, we may
                  be entitled to refuse to act on the request. Please consider
                  your request responsibly before submitting it. We’ll respond
                  as soon as we can. Generally, within 1 week of time. Please
                  keep in mind that there will be residual information that will
                  remain within our databases, access logs and other records,
                  which may or may not contain your Personal Information. Please
                  also note that certain Personal Information may be exempt from
                  such requests in certain circumstances, which may include if
                  we need to keep processing your Personal Information to comply
                  with a legal obligation.The various rights are not absolute
                  and are subject to certain exceptions or qualifications. In
                  order to exercise any of the rights described below please
                  contact us at support@defimarketspro.net
                </p>
                <br />
                <p>
                  <span className="font-bold"> 9. Legal Disclaimer</span>
                  <br />
                  We reserve the right to disclose your personal information as
                  required by law and when we believe that disclosure is
                  necessary to protect our rights and/or to comply with a
                  judicial proceeding, court order, or legal process served on
                  our Website. We will not be liable for misuse or loss of
                  personal information resulting from cookies on our website
                  that we don’t have access to or control over. We will not be
                  liable for unlawful or unauthorized use of your personal
                  information due to misuse or misplacement of your passwords,
                  negligent or malicious, however contacted.
                </p>
                <br />
                <p>
                  <span className="font-bold"> 8. Modifications</span>
                  <br />
                  Defi Markets Pro reserves the right to update or modify this
                  Privacy Policy at any time, without notice, by posting such
                  modifications on this web page. Such modifications will be
                  effective upon posting. You are deemed to consent to such
                  modifications by further using the services after such
                  modification.
                </p>
                <br />
                <p className="font-bold">Why Choose Us?</p>
                <ul>
                  <li>
                    Our team is comprised of industry experts who are driven to
                    consistently improve the trading experience through
                    experience.
                  </li>
                  <br />
                  <li>
                    Defi Markets Pro Account - The only 1 trading account type
                    for ease of your experience with us.
                  </li>
                </ul>
                <br />
                <p>
                  Zero deposit fees. We don't charge any commissions or fees on
                  your deposits and withdrawals.
                </p>
                <br />
                <p>
                  Licensed and Regulated. We respect and abide the industry
                  standards and regulations.
                </p>
                <br />
                <p>
                  Instant account opening and funding. Simple registration form
                  and fast verification.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SplitNoImage;
