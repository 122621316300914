const FaqTable = () => (
  <>
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 divide-y-2 divide-gray-200 sm:py-24 sm:px-6 lg:px-8">
        <h2
          style={{ color: "#1199FA" }}
          className="text-3xl font-extrabold text-gray-900"
        >
          Frequently asked questions
        </h2>
        <div className="mt-6 pt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12">
            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I open an account?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Our account registration process is as easy as clicking a 'Get
                Started' button anywhere on our website. They will all take you
                to the account registration page, or your dashboard if you have
                already logged in.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                Can I withdraw my money whenever I want?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Defenitely, all of your funds are for you to access and spend
                however you choose. Some Foreign Exchange trades however do
                require a margin, which means that your funds may be held in a
                trade tempoarily. Also, you cannot withdraw funds from your
                practice account.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I make a deposit?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Once logged into your account, you will find a 'deposit' panel
                as an option in the sidebar of your dashboard. This will enable
                you deposit really quickly.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                What is an NFT?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                An NFT is a digital asset that represents real-world objects
                like art, music, in-game items and videos. They are bought and
                sold online, frequently with cryptocurrency, and they are
                generally encoded with the same underlying software as many
                cryptos.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How do I buy an NFT?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                You can purchase an NFT by connecting your wallet. That being
                said, you will need an Ethereum wallet to continue. Luckily most
                wallets already support connecting to a web 3 website and you
                simply have to scan a QR code or find the wallet connect button.
                Once connected, you will see the option to purchase NFTs
                immediately.
              </dd>
            </div>

            <div>
              <dt className="text-lg leading-6 font-medium text-gray-900">
                How can I sell my NFT?
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                Your NFTs will be available to your wallet address. You may sell
                it on OpenSea or any other NFT Marketplace. We will provide the
                option for NFT sales soon.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </>
);

export default FaqTable;
