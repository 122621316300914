const Split = () => (
  <>
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full rounded-xl object-cover lg:absolute lg:h-full"
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1567&amp;q=80"
            alt=""
          />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h2
              style={{ color: "#1199FA" }}
              className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase"
            >
              ABOUT US
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Welcome to Defi Markets Pro
            </h3>
            <p className="mt-8 text-lg text-gray-500">
              Welcome to Defi Markets Pro, an international online Forex and
              CFDs trading firm offering 24 hours access to a diverse range of
              trading products including forex currency pairs, commodities,
              futures, indices and stocks. Through Defi Markets Pro and the
              world renowned MetaTrader 5 trading platform, you can take
              advantage of high liquidity, low spreads, mobile trading,
              technical analysis and even more.
            </p>
            <div className="mt-5 prose prose-indigo text-gray-500">
              <p>
                We maintain high standards to keep trading environments secure,
                and offer the best tailor made trading conditions for every
                client. We help Forex traders to develop the knowledge and
                skills they need to trade efficiently and responsibly.
              </p>
              <br />

              <p>
                With our Daily Market analysis, we bring the freshest market
                insights to our beloved clients every day. Experience it for
                yourself!
              </p>
              <br />
              <p className="font-bold">Why Choose Us?</p>
              <ul>
                <li>
                  Our team is comprised of industry experts who are driven to
                  consistently improve the trading experience through
                  experience.
                </li>
                <br />
                <li>
                  Defi Markets Pro Account - The only 1 trading account type for
                  ease of your experience with us.
                </li>
              </ul>
              <br />
              <p>
                Zero deposit fees. We don't charge any commissions or fees on
                your deposits and withdrawals.
              </p>
              <br />
              <p>
                Licensed and Regulated. We respect and abide the industry
                standards and regulations.
              </p>
              <br />
              <p>
                Instant account opening and funding. Simple registration form
                and fast verification.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Split;
