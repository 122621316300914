import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import InstrumentTable from "./InstrumentTable";
import IntroHeader from "./IntroHeader";
import SplitWithImage from "./SplitWithImage";

const TradingInstruments = () => {
  return (
    <div className="Instruments">
      <Header />
      <br />
      <br />
      <br />
      <IntroHeader
        title="Trading Instruments"
        desc="We provide a variety of trading instruments from diverse markets to smoothen your overall experience."
      />
      <br />
      <InstrumentTable />
      <SplitWithImage />
      <hr style={{ border: "0.5px solid #161C2A" }} />
      <Footer />
    </div>
  );
};

export default TradingInstruments;
