import React from "react";
import Header from "./Header";
import Split from "./Split";
import Branded from "./Branded";
import CTA from "./CTA";
import Footer from "./Footer";
import IntroHeader from "./IntroHeader";

const About = () => {
  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <IntroHeader
        title="About Us"
        desc="Making economic prosperity more accessible to everyone by providing useful diverse financial tools."
      />
      <br />
      <Split />
      <br />
      <Branded />
      <CTA />
      <Footer />
    </div>
  );
};

export default About;
