import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <footer
      style={{ backgroundColor: "#0E121B" }}
      className="bg-gray-800"
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about">
                      <p className="text-base text-gray-300 hover:text-white">
                        About us
                      </p>
                    </Link>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Platform
                    </a>
                  </li> */}

                  <li>
                    <Link to="/instruments">
                      <p className="text-base text-gray-300 hover:text-white">
                        Instruments
                      </p>
                    </Link>
                  </li>

                  <li>
                    <Link to="/faq">
                      <p className="text-base text-gray-300 hover:text-white">
                        FAQs
                      </p>
                    </Link>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Account types
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Trade
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.defimarketspro.net">NFTs</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.defimarketspro.net">Crypto</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.defimarketspro.net">Stocks</a>
                    </p>
                  </li>

                  <li>
                    <p className="text-base text-gray-300 hover:text-white">
                      <a href="https://app.defimarketspro.net">Forex</a>
                    </p>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      API Status
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/ib">
                      <p className="text-base text-gray-300 hover:text-white">
                        Become an IB
                      </p>
                    </Link>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      White Label
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Contact Us
                    </a>
                  </li> */}
                  {/* 
                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Sitemap
                    </a>
                  </li> */}
                  {/* 
                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Partners
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy">
                      <p className="text-base text-gray-300 hover:text-white">
                        Privacy
                      </p>
                    </Link>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Risk Disclosure
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Terms
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <a
                      href="mailto:support@defimarketspro.net"
                      className="text-base text-gray-300 hover:text-white font-bold"
                    >
                      Email Us
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      0780 990
                    </a>
                  </li> */}

                  <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Kumul highway, Port Vila, Vanuatu.
                    </a>
                  </li>

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Risk Disclosure
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      href="/"
                      className="text-base text-gray-300 hover:text-white"
                    >
                      Terms
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
              DISCLOSURE
            </h3>
            {/* <p className="mt-4 text-base text-gray-300">
              Defi Markets Pro is owned and operated by Global Markets, LLC.
              <br />
              <br />
              Our parent company is regulated through the U.S Securities and
              Exchange Commission to permitted to handle trading services. We
              are registered at 2700 Qubec Street NW, Washington DC with SEC
              number 0001025833
            </p> */}
            <br />
            <p className="mt-4 text-base text-gray-300">
              Risk Warning: Your Capital is not at risk. Our products are
              suitable for non-experienced traders and our investment is
              available in any country.
            </p>
          </div>
        </div>
        <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          © 2022 Defi Markets Pro, LLC. All rights reserved.
        </p>
      </div>
    </footer>
  </>
);

export default Footer;
