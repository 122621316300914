const IntroHeader = ({ title, desc }) => (
  <>
    <div className="" style={{ backgroundColor: "#0E121B" }}>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
        <div className="max-w-xl">
          <h2
            style={{ color: "#1199FA" }}
            className="text-4xl font-extrabold text-gray sm:text-5xl sm:tracking-tight lg:text-6xl"
          >
            {title}
          </h2>
          <p className="mt-5 text-xl text-gray-400">{desc}</p>
        </div>
      </div>
    </div>
  </>
);

export default IntroHeader;
