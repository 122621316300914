import { Link } from "react-router-dom";

const Panel = () => (
  <>
    <div className="bg-white ">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div
          style={{ backgroundColor: "#0E121B" }}
          className="bg-indigo-700  rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4"
        >
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block text-white">
                  Trade the global markets on a top-notch platform.
                </span>
              </h2>
              <p
                style={{ color: "#a3a3a3" }}
                className="mt-4 text-lg leading-6 text-indigo-200"
              >
                21 Timeframes, 6 types of pending orders, Hedging and Netting
                allowed and many more.
              </p>

              <Link to="/instruments">
                <p
                  style={{ backgroundColor: "#1199FA", color: "white" }}
                  className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                >
                  Read More
                </p>
              </Link>
            </div>
          </div>
          <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
            <img
              className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
              src="/trade.svg"
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default Panel;
