import React from "react";
import ContactForm from "./ContactForm";
import Footer from "./Footer";
import GridList from "./GridList";
import Header from "./Header";
import ImageWithStats from "./ImageWithStats";
import IntroHeader from "./IntroHeader";
import Text from "./Text";

const BecomeAnIB = () => {
  return (
    <div
      style={{
        backgroundColor: "#0E121B",
      }}
    >
      <Header />
      <br />
      <br />
      <br />
      <IntroHeader
        title="Becoming an IB"
        desc="The steps and benefits involved in partnering with us"
      />
      <br />
      <ImageWithStats />
      {/* <Text /> */}
      {/* <GridList /> */}
      <ContactForm />
      <Footer />
    </div>
  );
};

export default BecomeAnIB;
