import React from "react";
import IntroHeader from "./IntroHeader";
import Header from "./Header";
import FaqTable from "./FaqTable";
import Footer from "./Footer";

const FAQ = () => {
  return (
    <div>
      <Header />
      <br />
      <br />
      <br />
      <IntroHeader
        title="FAQs"
        desc="Some of our more popularly asked questions."
      />
      <br />
      <FaqTable />
      <Footer />
    </div>
  );
};

export default FAQ;
