import React from "react";

const InstrumentTable = () => {
  const assets = [
    { type: "Forex", name: "Euro Vs Dollar", Symbol: "EUR/USD" },
    {
      type: "Forex",
      name: "	Australian Dollar vs US Dollar",
      Symbol: "AUD/USD",
    },
    { type: "Forex", name: "US Dollar vs Japanese Yen", Symbol: "USD/JPY" },
    {
      type: "Forex",
      name: "New Zealand Dollar vs US Dollar",
      Symbol: "NZD/USD",
    },
    {
      type: "Forex",
      name: "New Zealand Dollar vs Japanese Yen",
      Symbol: "NZD/JPY",
    },
    { type: "Forex", name: "Swiss Frank vs Japanese Yen", Symbol: "CHF/JPY" },
    { type: "Forex", name: "	Euro vs Great Britain Pound", Symbol: "EUR/GBP" },
    { type: "Forex", name: "	Euro vs New Zealand Dolla", Symbol: "EUR/NZD" },
    {
      type: "Forex",
      name: "Great Britain Pound vs New Zealand",
      Symbol: "GBP/NZD",
    },
    { type: "Crypto", name: "	Bitcoin", Symbol: "BTCUSD" },
    { type: "Crypto", name: "	Ethreum", Symbol: "ETHUSD" },
    { type: "Crypto", name: "	Solana", Symbol: "SOLUSD" },
    { type: "Crypto", name: "	Bitcoin Cash", Symbol: "BCHUSD" },
    { type: "Crypto", name: "	Litecoin", Symbol: "LTCUSD" },
    { type: "Crypto", name: "	Dogecoin", Symbol: "DOGEUSD" },
    { type: "Crypto", name: "	Avalanche", Symbol: "AVAXUSD" },
    { type: "Crypto", name: "	Tether", Symbol: "USDTUSD" },
    { type: "Stock", name: "	Facebook", Symbol: "FB" },
    { type: "Stock", name: "	Apple", Symbol: "AAPL" },
    { type: "Stock", name: "Amazon", Symbol: "AMZN" },
    { type: "Stock", name: "	Microsoft", Symbol: "MSFT" },
    { type: "Stock", name: "	Google", Symbol: "GOOGL" },
  ];

  return (
    <>
      <div className="max-w-7xl mt-10 mx-auto sm:px-6 lg:px-8">
        {/* < className="px-6">Hello</button> */}
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b sm:rounded-lg">
                <table className="min-w-full divide-y">
                  <thead className="">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Symbol
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className="divide-y divide-gray-200"
                    data-todo-x-max="1"
                  >
                    {assets.map((asset) => (
                      <tr key={asset.Symbol}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {asset.type}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {asset.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {asset.Symbol}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstrumentTable;
